import BasePlugin from '../BasePlugin'

export default class CalculateDeprecations extends BasePlugin {
  async execute () {
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/InvestmentExpensesBudget/CalculateDeprecationCommand`,
      { 'investmentExpensesBudgetId': this.context.getModel().id },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      console.log('Амортизация расчитана')
      Object.values(this.context.getDashboardComponents()).forEach(function (item) {
        if (item[0].id === 1485) {
          item[0].loadData()
        }
      })
    })
  }
}
